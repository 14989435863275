import React from "react";
import { Route, Switch } from "react-router-dom";
import About from "./containers/About";
import Blog from "./containers/Blog";
import Listenings from "./containers/Listenings";
import Readings from "./containers/Readings";

export default () => (
  <Switch>
    <Route path="/" exact component={About} />
    <Route path="/blog" exact component={Blog} />
    <Route path="/readings" exact component={Readings} />
    <Route path="/listenings" exact component={Listenings} />
  </Switch>
);
