import { Col, Container, Image, Nav, Navbar, Row } from "react-bootstrap";

import React from "react";
import { SocialIcon } from "react-social-icons";
import headshot from "../headshot.jpeg";

const About: React.FC = () => {
  return (
    <div>
      <Container>
        <Row style={{ textAlign: "center", marginBottom: "16px" }}>
          <Col md="auto" className="mb-4">
            <Image
              src={headshot}
              roundedCircle
              style={{ height: 150, width: 150 }}
            />
          </Col>
          <Col style={{ textAlign: "left" }}>
            <p>Hey! I'm John.</p>
            <p>
              I'm working on social technology to help strengthen the
              relationships of your closest groups as one of the founding
              engineers at <a href="https://cocoon.com">Cocoon</a>. In general,
              I'm passionate about consumer tech with non-ad based revenue
              models, social psychology, music, and the outdoors.
            </p>
            <p>
              Before Cocoon, I was working on the new Chromium based Microsoft
              Edge. I built scalable infrastructure and privacy related
              microservices that are used by the 100+ million daily active users
              of Edge. Not so coincidentally, my career in technology kicked off
              by building with Microsoft products. When I was twelve, I started
              a business selling custom hacked Xbox 360s and games.
            </p>
            <p>
              During college at North Carolina State University, I started two
              companies that were ultimately unsuccessful but taught me
              invaluable engineering, product and business skills.{" "}
              <a href="http://www.technicianonline.com/news/article_184a8f34-051f-11e6-9b9c-4bf7e3cc6bcc.html">
                ShareFish
              </a>{" "}
              was a platform to help consumers make more educated decisions on
              what bars or restaurants to go out to. iBeacons bought by
              businesses would automatically check in the anonymized profile of
              users, allowing the users to then see the demographics (average
              age, gender ratio, in-a-relationship ratio) of clientele before
              deciding where to go. A year after that, I cofounded{" "}
              <a href="https://www.engr.ncsu.edu/news/2017/05/05/college-of-engineering-student-teams-place-at-annual-lulu-egames-startup-competition/">
                RipeNow
              </a>{" "}
              with{" "}
              <a href="https://www.linkedin.com/in/alextulenko/">
                Alex Tulenko
              </a>{" "}
              and{" "}
              <a href="https://www.linkedin.com/in/seth-mcmillan/">
                Seth McMillian
              </a>{" "}
              to simplify the supply chain of farm-to-table restaurants.
            </p>
            <p>
              I spend most of my free time working on side projects like{" "}
              <a href="https://apps.apple.com/us/app/quotable-scan-book-quotes/id1453927545">
                Quotable
              </a>
              , hiking, discovering <a href="/listenings">music</a>, and{" "}
              <a href="/readings">reading</a>. Please don't hesitate to reach
              out if you'd like to connect!
            </p>
          </Col>
        </Row>
        <Navbar
          className="footer"
          style={{
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          <Nav.Item style={{ textAlign: "left" }}>
            <SocialIcon
              style={{ height: 38, width: 38, marginRight: 5 }}
              fgColor="#ffffff"
              url="http://twitter.com/johnmalatras"
            />
            <SocialIcon
              style={{ height: 38, width: 38, marginRight: 5 }}
              fgColor="#ffffff"
              url="http://linkedin.com/in/johnmalatras"
            />
            <SocialIcon
              style={{ height: 38, width: 38, marginRight: 5 }}
              fgColor="#ffffff"
              url="http://github.com/johnmalatras"
            />
            <SocialIcon
              style={{ height: 38, width: 38, marginRight: 5 }}
              fgColor="#ffffff"
              url="mailto:john.malatras@gmail.com"
            />
          </Nav.Item>
        </Navbar>
      </Container>
    </div>
  );
};

export default About;
