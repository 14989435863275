import "./App.css";

import { Container, Nav, Navbar } from "react-bootstrap";
import { IndexLinkContainer, LinkContainer } from "react-router-bootstrap";

import React from "react";
import Routes from "./routes";

const App: React.FC = () => {
  return (
    <div className="App">
      <Container className="header">
        <h1>john demosthenes malatras</h1>
        <Navbar className="nav-bar">
          <Nav>
            <IndexLinkContainer className="no-focus" to="/">
              <Nav.Link>About</Nav.Link>
            </IndexLinkContainer>
            <LinkContainer className="no-focus" to="/readings">
              <Nav.Link>Readings</Nav.Link>
            </LinkContainer>
            <LinkContainer className="no-focus" to="/listenings">
              <Nav.Link>Listenings</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar>
      </Container>
      <div>
        <Routes />
      </div>
    </div>
  );
};

export default App;
