import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import { ListeningMonth } from "../models/listeningMonth";
import { MdHeadset } from "react-icons/md";
import React from "react";

// June '19
const jun_2019: ListeningMonth = {
  firstIFrame: getAlbumIframe("5zGidcaAvNahQrYhyQwAQn"),
  secondIFrame: getAlbumIframe("09rhLlnf4Jz5065HBGYCNI"),
  thirdIFrame: getAlbumIframe("2Vx9FC6Um8i6kEtY7HNswB"),
  title: "July 2019",
};

// July '19
const jul_2019: ListeningMonth = {
  firstIFrame: getAlbumIframe("42j41uUwuHZT3bnedq2XtM"),
  secondIFrame: getAlbumIframe("0CJvk7U4iDNOwrADAji00F"),
  thirdIFrame: getAlbumIframe("5WfDyog8yO7ZF8JdJxeZfl"),
  title: "July 2019",
};

// August '19
const aug_2019: ListeningMonth = {
  firstIFrame: getAlbumIframe("60xcVwuQJAOyu11xf9mObS"),
  secondIFrame: getAlbumIframe("1mRyfDR7Dc55GbC8V3kVis"),
  thirdIFrame: getAlbumIframe("2G2msv2JnORLxq0EDlv7Ch"),
  title: "August 2019",
};

// Sep '20
const sep_2019: ListeningMonth = {
  firstIFrame: getTrackIframe("29uGke73C3JUzciyUBRrkD"),
  secondIFrame: getAlbumIframe("4vjDYT5nXgLn12luv3mGjZ"),
  thirdIFrame: getAlbumIframe("0CTMk3XKsfG1LfmKu1KCCA"),
  title: "September 2019",
};

// Oct '20
const oct_2019: ListeningMonth = {
  firstIFrame: getAlbumIframe("2oegpcBiGzsD1JBXKZ9nWD"),
  secondIFrame: getAlbumIframe("4CuA5rkarVNoy14JDwai3K"),
  thirdIFrame: getAlbumIframe("7pg8T6pajjHVZbiyB8bGxo"),
  title: "October 2019",
};

// Nov '20
const nov_2019: ListeningMonth = {
  firstIFrame: getAlbumIframe("24JexjDMCOKOyUq04X82Tq"),
  secondIFrame: getTrackIframe("2P0NiuAfypaw5UZJoo0n0o"),
  thirdIFrame: getAlbumIframe("6Lq1lrCfkpxKa4jCo5gKWr"),
  title: "November 2019",
};

// Dec '20
const dec_2019: ListeningMonth = {
  firstIFrame: getAlbumIframe("1bgPsKJwqED6DoNaZNdM42"),
  secondIFrame: getAlbumIframe("2uhB1KivbFnlkARpbd0Cvu"),
  thirdIFrame: getAlbumIframe("1EZbgvaW3e8FQ3nkobmOT7"),
  title: "December 2019",
};

// Jan '20
const jan_2020: ListeningMonth = {
  firstIFrame: getAlbumIframe("6EdbqQvOCialJuOjEEUlsL"),
  secondIFrame: getAlbumIframe("0nVBHam69xP8JKcnduelr4"),
  thirdIFrame: getAlbumIframe("6qfXssEj5ifrHYiQfHmgYz"),
  title: "January 2020",
};

// Feb '20
const feb_2020: ListeningMonth = {
  firstIFrame: getAlbumIframe("5BAukn6AWk5tubfqjtN8gd"),
  secondIFrame: getTrackIframe("70nIZ6RlNhgqxcCShFmYwf"),
  thirdIFrame: getAlbumIframe("240qQIBOUZa4g3SPmxL5XN"),
  title: "February 2020",
};

// Mar '20
const mar_2020: ListeningMonth = {
  firstIFrame: getAlbumIframe("4wSb7OhVUzw3u76lta9fJw"),
  secondIFrame: getAlbumIframe("7MLDnEVUacleMDICnnAWuF"),
  thirdIFrame: getAlbumIframe("5gIa8hTQGPwVeNYjDwrraZ"),
  title: "March 2020",
};

// Apr '20
const apr_2020: ListeningMonth = {
  firstIFrame: getTrackIframe("664jBL8NgKFYdiMK9bnCcz"),
  secondIFrame: getAlbumIframe("09kooFexe8JArV583iIkaj"),
  thirdIFrame: getAlbumIframe("3e3Y0xzqF6CGHFeGolUVsE"),
  title: "April 2020",
};

// May '20
const may_2020: ListeningMonth = {
  firstIFrame: getAlbumIframe("0SrZP210qeRNZd2G3ciNVt"),
  secondIFrame: getTrackIframe("02VM3vd7Fj9pf0ojrzDOXB"),
  thirdIFrame: getAlbumIframe("45zKd7UeQyqJd4kWuKWnzt"),
  title: "May 2020",
};

// June '20
const june_2020: ListeningMonth = {
  firstIFrame: getAlbumIframe("4JLqd7uwcTMFjtfy2UUAZ7"),
  secondIFrame: getTrackIframe("3FCu8Cj7RBW3iHPaRPCurl"),
  thirdIFrame: getAlbumIframe("74r6JJ97ipO0CREXP9PMqZ"),
  title: "June 2020",
};

// July '20
const july_2020: ListeningMonth = {
  firstIFrame: getAlbumIframe("2ROQYFuwNtresrGarebbjN"),
  secondIFrame: getTrackIframe("7HZDGcBXoyF8hPzB9M9Xar"),
  thirdIFrame: getAlbumIframe("75vFTgDgdfVhbniLoyCNKh"),
  title: "July 2020",
};

// August '20
const aug_2020: ListeningMonth = {
  firstIFrame: getAlbumIframe("0m6cqrmHGd2pgVIL2CVPZL"),
  secondIFrame: getTrackIframe("7JMyLnG5gOrq48G9UU5bCg"),
  thirdIFrame: getTrackIframe("749Df1ZEqfj2PvUjaWyUdK"),
  title: "August 2020",
};

// September '20
const sep_2020: ListeningMonth = {
  firstIFrame: getAlbumIframe("4r8HtFE8TGFtqEpMV7mU60"),
  secondIFrame: getAlbumIframe("5FY4fAq3HOX4Y3HInvMzGn"),
  thirdIFrame: getAlbumIframe("70JL21ywMn2hLg3EyfwUPW"),
  title: "September 2020",
};

// October '20
const oct_2020: ListeningMonth = {
  firstIFrame: getAlbumIframe("1tYHjJ50WowcNvDTLdf6Wo"),
  secondIFrame: getAlbumIframe("26taoRjjIxl5l3IZqMgBLL"),
  thirdIFrame: getAlbumIframe("0lmjCPEcec2k6L7ysNIcd3"),
  title: "October 2020",
};

// November '20
const nov_2020: ListeningMonth = {
  firstIFrame: getAlbumIframe("4dCgqMWPJuBmL0BHiXwIEf"),
  secondIFrame: getTrackIframe("2VpJB1vzL9ZadZ3gF8GQBv"),
  thirdIFrame: getAlbumIframe("64rbCcTc040ycORN0NDJHX"),
  title: "November 2020",
};

// December '20
const dec_2020: ListeningMonth = {
  firstIFrame: getAlbumIframe("4ciK8DtBglOBd0FYznsg98"),
  secondIFrame: getAlbumIframe("2xECuqnvvmVktV7UO8Dd3s"),
  thirdIFrame: getTrackIframe("2JQ9wMmURQMY5ysth04rHO"),
  title: "December 2020",
};

// January '21
const jan_2021: ListeningMonth = {
  firstIFrame: getAlbumIframe("0lelIbxdFvpRiJQFaMdrg3"),
  secondIFrame: getAlbumIframe("5wnCTZtzIZxasRSHzI1JeW"),
  thirdIFrame: getTrackIframe("61Hn9ssQkVEWq4ZmPwHyzd"),
  title: "January 2021",
};

// Combined
const listenings: ListeningMonth[] = [
  jan_2021,
  dec_2020,
  nov_2020,
  oct_2020,
  sep_2020,
  aug_2020,
  july_2020,
  june_2020,
  may_2020,
  apr_2020,
  mar_2020,
  feb_2020,
  jan_2020,
  dec_2019,
  nov_2019,
  oct_2019,
  sep_2019,
  aug_2019,
  jul_2019,
  jun_2019,
];

function getTrackIframe(spotifyUri: string): string {
  return `<iframe src="https://open.spotify.com/embed/track/${spotifyUri}" width="250" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`;
}

function getAlbumIframe(spotifyUri: string): string {
  return `<iframe src="https://open.spotify.com/embed/album/${spotifyUri}" width="250" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`;
}

function Iframe(props: { iframe: string }) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}

const Listenings: React.FC = () => {
  const timelineElements = listenings.map((listening, index) => {
    return (
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date={listening.title}
        iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
        icon={index === 0 ? <MdHeadset /> : null}
      >
        <Iframe iframe={listening.firstIFrame} />
        <Iframe iframe={listening.secondIFrame} />
        <Iframe iframe={listening.thirdIFrame} />
      </VerticalTimelineElement>
    );
  });

  return (
    <div className="timeline-margin">
      <VerticalTimeline>{timelineElements}</VerticalTimeline>
    </div>
  );
};

export default Listenings;
