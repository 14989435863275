import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import { Book } from "../models/book";
import { Collapse } from "react-bootstrap";
import { FaBook } from "react-icons/fa";
import React from "react";

interface IState {
  showCandideText: boolean;
  showSleepText: boolean;
}

interface IProps {}

// Going to have to update this to handle inline reviews at some point
const books: Book[] = [
  {
    title: "The Death of Ivan Ilyich and Other Stories",
    author: "Leo Tolstoy",
    date: "January 2021",
  },
  {
    title: "Why We're Polarized",
    author: "Ezra Klein",
    date: "January 2021",
  },
  {
    title: "Shadow Divers",
    author: "Robert Kurson",
    date: "December 2020",
  },
  {
    title: "The Ministry for the Future",
    author: "Kim Stanley Robinson",
    date: "December 2020",
  },
  {
    title: "Into the Wild",
    author: "Jon Krakauer",
    date: "November 2020",
  },
  {
    title: "Nine Stories",
    author: "J. D. Salinger",
    date: "November 2020",
  },
  {
    title: "Into Thin Air",
    author: "Jon Krakauer",
    date: "November 2020",
  },
  {
    title:
      "The Immortality Key: The Secret History of the Religion with No Name",
    author: "Brian C. Muraresku",
    date: "October 2020",
  },
  {
    title: "Flowers for Algernon",
    author: "Daniel Keyes",
    date: "September 2020",
  },
  {
    title:
      "The Hard Thing About Hard Things: Building a Business When There Are No Easy Answers",
    author: "Ben Horowitz",
    date: "September 2020",
  },
  {
    title: "Hiking Alaska's Wrangell-St. Elias National Park and Preserve",
    author: "Greg Fensterman",
    date: "August 2020",
  },
  {
    title: "The Overstory",
    author: "Richard Powers",
    date: "August 2020",
  },
  {
    title:
      "The Deficit Myth: Modern Monetary Theory and the Birth of the People's Economy",
    author: "Stephanie Kelton",
    date: "July 2020",
  },
  {
    title: "The Moth Presents: All These Wonders",
    author: "Various Authors",
    date: "June 2020",
  },
  {
    title: "Wilderness Essays",
    author: "John Muir",
    date: "June 2020",
  },
];

export default class Readings extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showCandideText: false,
      showSleepText: false,
    };
  }

  render() {
    const latestBooks = books.map((book, index) => {
      return (
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date={book.date}
          iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          icon={index === 0 ? <FaBook /> : null}
        >
          <h4 className="vertical-timeline-element-title">
            <u>{book.title}</u>
          </h4>
          <h5 className="vertical-timeline-element-subtitle">{book.author}</h5>
          <p></p>
        </VerticalTimelineElement>
      );
    });

    return (
      <div className="timeline-margin">
        <VerticalTimeline className="black-links">
          {latestBooks}
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="May 2020"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<FaBook />}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/gp/product/B0058Z4NR8/ref=ppx_yo_dt_b_d_asin_title_o01?ie=UTF8&psc=1">
                  Why Nations Fail
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">
              Daron Acemoglu & James A. Robinson
            </h5>
            <p></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="April 2020"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/0008296855/ref=cm_sw_em_r_mt_dp_U_BKVnEb2V4FJ9X">
                  No Filter: The Inside Story of Instagram
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">Sarah Frier</h5>
            <p></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="April 2020"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/gp/product/B07GD46PQZ/ref=ppx_yo_dt_b_d_asin_title_o03?ie=UTF8&psc=1">
                  Exhalation: Stories
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">Ted Chiang</h5>
            <p></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="March 2020"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/gp/product/B07MNG496J/ref=ppx_yo_dt_b_d_asin_title_o04?ie=UTF8&psc=1">
                  This Is How You Lose the Time War
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">
              Amal El-Mohtar & Max Gladstone
            </h5>
            <p></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="February 2020"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/gp/product/B00G3L6JMS/ref=ppx_yo_dt_b_d_asin_title_o07?ie=UTF8&psc=1">
                  Barbarian Days
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">
              William Finnegan
            </h5>
            <p></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="January 2020"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/0008296855/ref=cm_sw_em_r_mt_dp_U_BKVnEb2V4FJ9X">
                  Uncanny Valley
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">Anna Weiner</h5>
            <p></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="January 2020"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/1909621986/ref=cm_sw_em_r_mt_dp_U_BJVnEbH3YZETD">
                  My Family and Other Animals
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">
              Gerald Durrell
            </h5>
            <p></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="December 2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/0374534888/ref=cm_sw_em_r_mt_dp_U_KHVnEbCBN1909">
                  Happy City
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">
              Charles Montgomery
            </h5>
            <p></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="December 2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/1400079985/ref=cm_sw_em_r_mt_dp_U_XKmwDb288HWER">
                  War & Peace
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">Leo Tolstoy</h5>
            <p></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="November 2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/1101982934/ref=cm_sw_em_r_mt_dp_U_pWc5Db84MGK9Z">
                  The Upside of Stress
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">
              Kelly McGonigal
            </h5>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="November 2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/081298840X/ref=cm_sw_em_r_mt_dp_U_5Vc5DbS9X53KR">
                  When Breath Becomes Air
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">
              Paul Kalanithi
            </h5>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="October 2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/0143110438/ref=cm_sw_em_r_mt_dp_U_WUc5DbVNRY700">
                  A Gentleman in Moscow
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">Amor Towles</h5>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="October 2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/0060505915/ref=cm_sw_em_r_mt_dp_U_jTc5DbHA79S02">
                  The True Believer
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">Eric Hoffer</h5>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="September 2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/1591797691/ref=cm_sw_em_r_mt_dp_U_gRc5Db1M4HRBJ">
                  The Language of Emotions
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">
              Karla McLaren
            </h5>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="September 2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/0393652246/ref=cm_sw_em_r_mt_dp_U_0MaDDbA2SARJJ">
                  Super Pumped
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">Mike Isaac</h5>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="August 2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/0804139296/ref=cm_sw_em_r_mt_dp_U_KNaDDbMA3BWG9">
                  Zero to One
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">Peter Thiel</h5>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="August 2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/1524761419/ref=cm_sw_em_r_mt_dp_U_3MmwDb5ETMZQ2">
                  Blitzscaling
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">Reid Hoffman</h5>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="August 2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/1503253791/ref=cm_sw_em_r_mt_dp_U_XNmwDb2WBXH2C">
                  Candide
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">Voltaire</h5>
            <p>
              The is the first enlightenment era book I’ve voluntarily read— a
              lot has changed since high school. My impression of pre-20th
              century satire was always that of being funny at its inception but
              read more as a drama today. This was not true with{" "}
              <em>Candide</em>. I laughed out loud several times throughout the
              book and it only gets funnier as you begin to understand the
              context of Voltaire and{" "}
              <a href="https://en.wikipedia.org/wiki/Gottfried_Wilhelm_Leibniz">
                Gottfried Leibniz’s
              </a>{" "}
              rivalry.
            </p>
            <p>
              <a
                style={{ color: "blue" }}
                hidden={this.state.showCandideText}
                onClick={() =>
                  this.setState({
                    showCandideText: !this.state.showCandideText,
                  })
                }
              >
                Read more {">"}
              </a>
            </p>
            <Collapse in={this.state.showCandideText}>
              <div>
                <p>
                  Candide, the protagonist, is a follower of one Professor
                  Pangloss who philosophizes that they're living in the “best of
                  all possible worlds.” This belief, which is really just{" "}
                  <a href="https://en.wikipedia.org/wiki/Gottfried_Wilhelm_Leibniz#Theodicy_and_optimism">
                    Leibnizian optimism
                  </a>
                  , is systematically dismantled as Candide’s naiveté robs him
                  of his home, teacher, wealth, lover and eventually love
                  itself. It turns out that <em>Candide</em> may have just been
                  a hit piece on Leibniz. I left this book thirsty for more
                  enlightenment satire and really wishing that we could witness
                  a rap battle esque debate between these guys.
                </p>
                <p>
                  Quotes:
                  <ul>
                    <li>
                      “…man was born to live either in the convulsions of
                      misery, or in the lethargy of boredom."
                    </li>
                    <li>
                      “In short, my dear miss, I have a great deal of knowledge
                      and experience in the world; therefore, take my
                      advice—divert yourself, and ask each passenger to tell his
                      story, and if there is one of them all who has not cursed
                      his existence many times, and said to himself over and
                      over again that he was the most miserable of men, I give
                      you permission to throw me head-first into the sea."
                    </li>
                  </ul>
                </p>
                <p>
                  <a
                    style={{ color: "blue" }}
                    hidden={!this.state.showCandideText}
                    onClick={() =>
                      this.setState({
                        showCandideText: !this.state.showCandideText,
                      })
                    }
                  >
                    Read less {"<"}
                  </a>
                </p>
              </div>
            </Collapse>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="August 2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/1501144324/ref=cm_sw_em_r_mt_dp_U_HQmwDbKCF2DB3">
                  Why We Sleep
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">
              Matthew Walker, Ph.D.
            </h5>
            <p>
              Rarely does a book prompt immediate change in my daily life, but
              it only took a few chapters for Why We Sleep to do just that. Most
              of the content is terrifying and eye opening. Everyone knows that
              sleep is important— and that we’re not getting enough of it— but
              there’s something vividly terrifying about the way Dr. Matthew
              Walker describes the biological and neurochemical effects of its
              absence. Not only is the damage immense and irrevocable, it’s
              triggered by seemingly trivial declines below eight hours. In one
              study referenced, researchers found that sleeping seven hours per
              night for two weeks caused the same cognitive impairment as
              pulling an all nighter. This reduces creativity, memory and
              critical thinking, and contrary to popular belief you can never
              actually “catch up.”{" "}
            </p>
            <p>
              <a
                style={{ color: "blue" }}
                hidden={this.state.showSleepText}
                onClick={() =>
                  this.setState({
                    showSleepText: !this.state.showSleepText,
                  })
                }
              >
                Read more {">"}
              </a>
            </p>
            <Collapse in={this.state.showSleepText}>
              <div>
                <p>
                  Another interesting concept is that humans are biologically
                  biphasic— we yearn for a full night of sleep followed by an
                  afternoon nap. One study of Ikaria, Greece, a fabled “
                  <a href="https://en.wikipedia.org/wiki/Blue_Zone">
                    Blue Zone
                  </a>
                  ”, showed that those who take an afternoon nap are 40% more
                  likely to live to 80. Einstein took advantage of this, plus
                  the significant creativity boosts acquired from REM sleep, by
                  taking naps with a pan next to his chair and marbles in hand.
                  When he fell asleep, the marbles would clatter on the pan,
                  waking him. He’d then jot down solutions to whatever problem
                  he was facing.
                </p>
                <p>
                  Overall, this has been one of my favorite non-fictions of the
                  year. As I mentioned, it spurred a change in my schedule and I
                  shifted my morning workout back by about 45 minutes. Highly
                  recommend!
                </p>
                <p>
                  Quotes:
                  <ul>
                    <li>
                      “…there are more than twenty large-scale epidemiological
                      studies that have tracked millions of people over many
                      decades, all of which report the same clear relationship:
                      the shorter your sleep, the shorter your life."
                    </li>
                    <li>
                      “Indeed, journaling your waking thoughts, feelings, and
                      concerns has a proven mental health benefit, and the same
                      appears true of your dreams. A meaningful, psychologically
                      healthy life is an examined one, as Socrates so often
                      declared."
                    </li>
                    <li>
                      “Prather retrospectively separated the participants into
                      four sub-groups on the basis of how much sleep they had
                      obtained in the week before being exposed to the common
                      cold virus... The less sleep an individual was getting in
                      the week before facing the active common cold virus, the
                      more likely it was that they would be infected and catch a
                      cold. In those sleeping five hours on average, the
                      infection rate was almost 50 percent. In those sleeping
                      seven hours or more a night in the week prior, the
                      infection rate was just 18 percent."
                    </li>
                  </ul>
                </p>
                <p>
                  <a
                    style={{ color: "blue" }}
                    hidden={!this.state.showSleepText}
                    onClick={() =>
                      this.setState({
                        showSleepText: !this.state.showSleepText,
                      })
                    }
                  >
                    Read less {"<"}
                  </a>
                </p>
              </div>
            </Collapse>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="July 2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/0061148520/ref=cm_sw_em_r_mt_dp_U_7RmwDbBNZQWAY">
                  The Unbearable Lightness of Being
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">
              Milan Kundera
            </h5>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="July 2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/0374533555/ref=cm_sw_em_r_mt_dp_U_4UmwDbGQ0R2FA">
                  Thinking, Fast and Slow
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">
              Daniel Kahneman
            </h5>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="June 2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/0553418831/ref=cm_sw_em_r_mt_dp_U_bWmwDbB9CJMW4">
                  Weapons of Math Destruction
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">Cathy O'Neil</h5>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="June 2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/1250103509/ref=cm_sw_em_r_mt_dp_U_32mwDbNFP2M49">
                  Radical Candor
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">Kim Scott</h5>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="May 2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          >
            <h4 className="vertical-timeline-element-title">
              <u>
                <a href="https://www.amazon.com/dp/1610395697/ref=cm_sw_em_r_mt_dp_U_eYmwDbW3QYTPW">
                  The Age of Surveillence Capitalism
                </a>
              </u>
            </h4>
            <h5 className="vertical-timeline-element-subtitle">
              Shoshana Zuboff
            </h5>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    );
  }
}
