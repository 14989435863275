import React from "react";

const Blog: React.FC = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h4>Coming soon!</h4>
    </div>
  );
};

export default Blog;
